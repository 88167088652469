footer {
  background-color: #000000bf;
  border-top: 2px solid var(--p500);
  color: var(--n700);
  border-top: 4px solid var(--p200);
  padding: 2rem;
}

footer .content {
  max-width: var(--width-limit);
  margin: 2rem auto
}

footer .column section  {
  padding: 0;
  margin: 0;
  font-size: var(--font-xs);
}

footer section {
  text-align: left;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5rem;
}

footer h3 {
  color: var(--p500);
}

footer h4 {
  color: var(--s500);
}

footer .column section.about-me ul {
  margin: 0;
  padding-left: 1rem;
}

footer .column section.about-me ul li {
  list-style: none;
  margin: 0;

}

footer .column section p {
  padding: 0;
}


.techs-container {
  padding: 4rem 0;
}

ul.powered{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
  padding-inline-start: 0;

}

ul.powered > li {
 	display: block;
	list-style: none;
}

ul.powered > li > a > img {
	width: 40px;
  filter: brightness(0.8) contrast(0.8) sepia() hue-rotate(112deg) saturate(6);
  mix-blend-mode: lighten;
}

@media (max-width: 1024px) {
  .columns {
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
  }
  
  footer .content {
    padding: .5rem;
  }
}